import Icon1 from '../../images/icon-wheel.svg'
import Icon2 from '../../images/icon-shield.svg'
import Icon3 from '../../images/icon-prog.svg'

import Image1 from '../../images/001-wheel-fill.svg'
import Image2 from '../../images/001-shield-fill.svg'
import Image3 from '../../images/001-prog-fill.svg'

export const MenuItemsReferences = [
  {
    title: 'MoCCA',
    path: '/referenceMocca',
    cName: 'dropdown-link',
    icon: Icon1,
    image: Image1,
    text: 'Mocular Car Computing Architectur.\n\n',
  },
  {
    title: 'PiezoLith P3000 Plus',
    path: '/referencePiezolith',
    cName: 'dropdown-link',
    icon: Icon2,
    image: Image2,
    text: 'PiezoLith P3000 Plus Text',
  },
  {
    title: 'Entwicklungs Infrastruktur',
    path: '/referenceInfraStructure',
    cName: 'dropdown-link',
    icon: Icon3,
    image: Image3,
    text: 'Hochintegrierte Entwicklungs Infrastruktur',
  },
  ];

import React from "react";
import {Component} from 'react';

import "./ReferenceTable.css"

class ReferenceTable extends Component
{
    render()
    { 
        return(
            <>
                <table class="referencetable">
                    <tr>
                        <th>Meine Aufgaben:</th>
                        <td>
                            {this.props.myrole}
                        </td>
                    </tr>
                    <tr>
                        <th>Werkzeuge/Technologien:</th>
                        <td>                        
                            {this.props.tools}
                        </td>
                    </tr>
                    <tr>
                        <th>Kunde:</th>
                        <td>                        
                            {this.props.customer}
                        </td>
                    </tr>
                    <tr>
                        <th>Projektumfang:</th>
                        <td>                        
                            {this.props.scope}
                        </td>
                    </tr>
                    <tr>
                        <th>Zeitraum</th>
                        <td>
                            {this.props.timespan}
                        </td>
                    </tr>
                </table>
            </>
        )
    }
}

export default ReferenceTable;
import { Fragment } from 'react'

import StartImg from '../images/BG.jpg'

export default function Start() {
    return (
        <div className="start-component">
            <div className="start-container">

                <div className="start-text-content">
                    <hr class="hairline-display border-black" />
                    <h1 className="start-headline">
                        <span className="block xl:inline text-black">
                            Willkommen bei Rüdiger Bayer
                        </span>
                    </h1>
                    <p className="start-sub-headline text-black">
                        Meine besondere Stärke liegt darin Ihre Mitarbeiter im Wandel zu unterstützen. 
                    </p>
                    <p className="start-sub-headline text-black">
                        Neue Themen anschaulich zu vermitteln, Menschen abzuholen, zu neuen Wegen zu führen und Blockaden zu lösen ist meine Passion. 
                    </p>
                </div>

            </div>
            <div className="background">
                <img src={StartImg} alt="blueImage" className="backgroundImg"></img>
            </div>
        </div >
    )
}
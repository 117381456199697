import React, { Component }  from 'react'

import Start from '../01-Start';
import Values from '../02-Values';
import Services from '../03-Services';
import References from '../04-References.js';
import Person from '../05-Person.js';
import Contact from '../06-Contact';

class MainPage extends Component
{
    render()
    { 
        return (
            <>
                <section id='start'>
                    <Start />
                </section>
                 <section id='services'>
                    <Services />
                    </section>
               <section id='references'>
                    <References />
                </section>
                <section id='person'>
                    <Person />
                </section>
                <section id='contact'>
                    <Contact />
                </section>
    
            </>
        )
    }
}
 
 export default MainPage;
import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';


class Location extends Component
{
    render()
    { 
        return (
        <>
            <div className="belowNavbar"></div>
        
            <h4 className="breadcrumb">
                <Link to={"/#" + (this.props.parentLink)} className="breadcrumb" >
                    {this.props.parentName}
                    <svg class="w-3 h-3 mx-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 5l7 7-7 7"></path>
                    </svg>
                </Link>
                <span className="opacity-80">
                    {this.props.thisPage}
                </span>
            </h4>
        </>
        )

    }
}

export default Location;
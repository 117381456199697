import { HashLink as Link } from 'react-router-hash-link';

import Card from './design-components/Card';
import TopicHeadline from './design-components/TopicHeadline'

import { MenuItems } from './navbar-with-dropdown/MenuItems';

import ImageOne from '../images/01-wheel.svg';
import ImageTwo from '../images/01-shield.svg';
import ImageThree from '../images/01-prog.svg';
import ImageFour from '../images/01-method.svg';

import ImageOneFill from '../images/001-wheel-fill.svg';
import ImageTwoFill from '../images/001-shield-fill.svg';
import ImageThreeFill from '../images/001-prog-fill.svg';
import ImageFourFill from '../images/001-method-fill.svg';

export default function Services() {
    return (
        <>
            <div id='services'>
                <TopicHeadline headline="Leistungen" bgColor="bg-blue500" textColor="text-white" />

                <div className="card-bg-responsive bg-blue500">

                    <Card
                        img={ImageOne}
                        imgFill={ImageOneFill}
                        title="Automotive SPICE"
                        link="/service1"
                        content="ASPICE ist der weltweit führende Standard zur Beurteilung der Entwicklung von softwaregesteuerten Systemen im Automobil."
                    >
                        <p>dies ist der Text</p>
                    </Card>
                    <Card
                        img={ImageTwo}
                        imgFill={ImageTwoFill}
                        title="Funktionale Sicherheit ISO 26262"
                        link="/service2"
                        content="Funktionale Sicherheit befasst sich mit Gefährdungen für Insassen und Umwelt, die von elektrischen und/oder elektronischen Systeme in Straßenfahrzeugen ausgehen."
                    />
                    <Card
                        img={ImageThree}
                        imgFill={ImageThreeFill}
                        title="Programmier-Schulungen"
                        link="/service3"
                        content="Programmierschulungen für C/C++, C# und VBA bilden den Grundumfang meines Angebots, bei Bedarf biete ich aber auch Schulungen und Workshops in den Bereichen SQL, Javascript, PHP und Velocity an."
                    />
                    <Card
                        img={ImageFour}
                        imgFill={ImageFourFill}
                        title="Methodische Schulungen und Software Engineering"
                        link="/service4"
                        content="
                        Die meisten Software Projekte scheitern nicht aufgrund der Programmierung selbst. 
                        Schlechte Infrastruktur, falsche Methodik oder unklare Anforderungen sind die häufigsten Ursachen."
                        
                        unused="
                        Die meisten Software Projekte scheitern nicht aufgrund der Programmierung selbst. 
                        Schlechte Infrastruktur, falsche Methodik oder unklare Anforderungen sind die häufigsten Problemursachen. 
                        Aus mehr als 30 Jahren Praxiserfahrung biete ich Schulungen udn Workshops an, die sich mit dem Umfeld der Software Entwicklung befassen. 
                        Themen sind hier 
                        Testgetriebene Softwareentwickung (TDD), 
                        Modellgetriebene Architektur (MDA), 
                        Umgang mit Versionskontrollsystemen aber auch 
                        Critical Chain Project Management (CCPM)
                        "
                    />
                </div>
            </div>
        </>
    );

}

import TopicHeadline from './design-components/TopicHeadline'
import TextContainer from './design-components/TextContainer'
import TextLineBreak from './functional-components/TextLineBreak.js'
import MyPicture from '../images/rab_rund.png'

export default function Person() {
    return (
        <>
            <TopicHeadline headline="Über mich" bgColor="" textColor="text-darkblue1" />

            <div className="card-bg-responsive">
                <div className="spacing-content person-container">
                    <img className="person-img" src={MyPicture} alt=""></img>

                    <TextContainer
                        component="person"
                        title="Hallo, ich bin Rüdiger Bayer"
                        subtitle="Man hilft den Menschen nicht, wenn man für sie tut, was sie auch selbst tun können. - Abraham Lincoln"
                        content={
                            <TextLineBreak
                                text={'Meine besondere Stärke liegt darin Ihre Mitarbeiter im Wandel zu unterstützen.\n'
                                + 'Neue Themen anschaulich zu vermitteln, Menschen abzuholen, zu neuen Wegen zu führen und Blockaden zu lösen ist meine Passion.\n\n'
                                + 'Meine technische Qualifikation dient mir dazu als Grundlage, 30 Jahre Erfahrung in Schulung und Coaching sichern den Erfolg. '
                                + 'Bei Bedarf unterstütze ich mit reichlich Erfahrung im Tooling, beim Aufbau einer Entwicklungs-Infrastruktur und im Bereich Methodik und Prozesse. '
                                + 'Dabei ist es jedoch stets mein Ziel, die von mir erbrachte Leistung so an den Kunden weiterzureichen, '
                                + 'dass er in der Zukunft in der Lage ist diese Verantwortung für die Pflege und Weiterentwicklung der Ergebnisse im eigenen Haus übernehmen kann.\n\n'
                                + 'Privat bin ich (unter anderem)\n'
                                + '- leidenschaftlicher Motorradfahrer, \n'
                                + '- entschiedener Christ, \n'
                                + '- begeisterter Segler, \n'
                                + '- E-Biker, \n'
                                + '- Wanderer und auch \n'
                                + '- ein bisschen ein Autonarr'} />
                        }
                    >
                    <p>Als leidenschaftlicher Coach habe ich diesen Ausspruch von Abraham Lincoln zum Leitsatz meiner Tätigkeit gemacht.</p>
                    <p>Meine besondere Stärke liegt darin Ihre Mitarbeiter im Wandel zu unterstützen. </p>
                    <p>Neue Themen anschaulich zu vermitteln, Menschen abzuholen, zu neuen Wegen zu führen und Blockaden zu lösen ist meine Passion.</p>
                    <p>Meine technische Qualifikation dient mir dazu als Grundlage, 30 Jahre Erfahrung in Schulung und Coaching sichern den Erfolg.
                        Bei Bedarf unterstütze ich mit reichlich Erfahrung im Tooling, beim Aufbau einer Entwicklungs-Infrastruktur und im Bereich Methodik und Prozesse.
                        Dabei ist es jedoch stets mein Ziel, die von mir erbrachte Leistung so an den Kunden weiterzureichen,
                        dass er in der Zukunft die Verantwortung für die Pflege und Weiterentwicklung der Ergebnisse im eigenen Haus übernehmen kann.
                    </p>
                    <p>
                        Privat bin ich (unter anderem)
                        <il>
                            <li>leidenschaftlicher Motorradfahrer,</li>
                            <li>entschiedener Christ,</li>
                            <li>begeisterter Segler,</li>
                            <li>E-Biker,</li>
                            <li>Wanderer und auch</li>
                            <li>ein bisschen ein Autonarr</li>
                        </il>
                    </p>
                    </TextContainer>
                </div>
            </div>
        </>
    );

}
import React from 'react';
import { Component } from 'react';

import TextContainer from '../design-components/TextContainer.js';
/*import TextLineBreak from '../functional-components/TextLineBreak.js';*/
import ReferenceTable from '../design-components/ReferenceTable.js';
import Location from '../design-components/Location.js'

import ImageP3000Plus from '../../images/P3000plus.jpg';
import "./ReferencePage.css";

class ReferencePiezolith extends Component
{
    render()
    { 
        return(

            <>
              <Location parentLink="references" parentName="Referenzen" thisPage="Piezolith" />
      
              <div className="referencepage-bg-responsive">
                <div className="spacing-content referencepage-container">
                  <div className="referencepage-img-container">
                    <img className="referencepage-img" src={ImageP3000Plus} alt=""></img>
                  </div>
                  <div className="referencepage-text-container">
                    <TextContainer
                      title="Piezolith 3000 Plus"
                    >
                      <p>
                        Das urologische Stone-Management umfasst zwischenzeitlich eine Vielzahl von Verfahren, in denen sich die extrakorporale Stoßwellen-Lithotripsie als zentrale Behandlung etabliert hat. 
                      </p>
                      <p>
                        Der PiezoLith 3000plus bietet neben der Behandlung von Nieren- Harnleiter- und Blasensteinen auch die Möglichkeit, orthopädische, sowie gastroenterologische Applikationen vorzunehmen.
                      </p>
                      <p>
                        Der PiezoLith verfügt über eine innovative, weltweit einmalige computer- und softwaregestützte In-Bild-Navigation mit Ultraschall- und Röntgenortung
                      </p>
                      <p/>
                      <hr/>
                    </TextContainer>
                    <ReferenceTable
                      myrole="Requirementsmanagement, Architektur, Implementierung, Test, Tooling"
                      tools="Qt, Eclipse, Linux, Corba, ACE/TAO"
                      customer="Richard Wolf GmbH, Knittlingen"
                      timespan="2010 bis 2016"
                      />
                     </div>
                </div>
              </div>
          </>
                     
        )
    }
}

export default ReferencePiezolith;


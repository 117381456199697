import React from 'react';
import { Component } from 'react';

import TextContainer from '../design-components/TextContainer.js';
/*import TextLineBreak from '../functional-components/TextLineBreak.js';*/
import ReferenceTable from '../design-components/ReferenceTable.js';
import Location from '../design-components/Location.js'

import ImageP3000Plus from '../../images/P3000plus.jpg';
//import ImageInfraStructure from '../../images/RwInfrastructureComponents.svg';
import ImageInfraStructure from '../../images/DevInfraStructure.svg';
//src/images/001-wheel-fill.svg
import "./ReferencePage.css";

class ReferenceInfraStructure extends Component
{
    render()
    { 
        return(
            <>
              <Location parentLink="references" parentName="Referenzen" thisPage="Piezolith" />
      
              <div className="referencepage-bg-responsive">
                <div className="spacing-content referencepage-container">
                  <div className="referencepage-img-container">
                    <img className="referencepage-img" src={ImageInfraStructure} alt=""></img>
                  </div>
                  <div className="referencepage-text-container">
                    <TextContainer
                      title="Hochintegrierte Entwicklungs-InfraStruktur"
                    >

<h3>Motivation</h3>
<p>
Die Medizintechnik erfordert umfangreiche Dokumentation von Produkt und Entwicklung, um die Zulassung des Produkt zu ermöglichen. 
Um in diesem Umfeld die einmal erfasste Information in allen benötigten Bereichen mit möglichst wenig Redundanz wiederzuverwenden wurden die im 
Unternehmen vorhandenen Entwicklungswerkzeuge vervollständigt und so verknüpft, dass eine integrierte Entwicklungs- und Dokumentationsinfrastruktur entsteht.
</p><p>
Die einzelnen Werkzeuge wurden über verfügbare Plugins oder durch Programme in PHP, Javasctipt, C# oder SQL so verknüpft dass die Information an allen notwendigen Stellen zur Verfügung steht, 
jedoch die Pflege immer im geeignetsten Werkzeug stattfindet (Single Point of Trouth)
</p>
<h3>Polarion</h3>
<p>
Das im Unternehmen bereits für das Anforderungsmanagement eingesetzte Polarion wurde um die Erfassung von Produktdaten und klinische Bewertungen erweitert. 
Die bereits im dem ERP vorhandenen Daten werden zu diesem Zweck in Polarion gespiegelt. 
</p>
<h3>Jira/Confluence</h3>
<p>
Jira wird im Unternehmen zur Feinplanung und Rückverfolgung der Entwicklungsaufgaben eingesetzt. Durch eine Brücke zu Lynx wird sichergestellt, dass die aktuellen Information aus Jira im Projekt und Portfoliomanagement verfügbar sind. 
</p>

<h3>A-Dato Lynx</h3>
<p>
  A-Dato Lynx wird für das Projekt-und Porfoliomanagement nach der CCPM Methode eingesetzt. Durch eine Brücke zu Jira werden die Information aus Lynx mit dem restlichen System synchronisiert. Zudem wurde das System um Diesnte erweitert, die im Hintergrund Daten exportieren und statistisch auswerten. 
</p>

<h3>SAP</h3>
<p>
Das in SAP implementierte ERP wird durch Datenbankdienste mit den Informaton aus den anderen Systemen synchronisiert. 
</p>

<h3>Dashboard</h3>
<p>
Ein im Intranet verfügbares Dashboard zeigt Informationen aus den verschiedenen Teilen der Entwcklungsinfrastruktur übersichtlich an und ernöglich dem Managment schnell ENtscheidung aufgrund der vorhandene Daten zu Treffen.Das Dashboard greift dazu über PHP auf die datnenbanken oder auf mit zusätzliche Diensten aufbereitete Daten zu  
</p>

<h3>OTRS</h3>
<p>
Das im Kundendienst eingesetzte Open Ticket Request System ist über eine Verbindung zu Jira in das Gesamtsystem so eingebunden, das relevante Daten in beide Richtungen ausgetauscht werden können.                      
</p>
                      <hr/>
                    </TextContainer>
                    <ReferenceTable
                      myrole="Architektur, Implementierung, Test, Tooling"
                      tools="Polarion, Jira, Confluence, OTRS, Lynx, PHP, SAP R3, ECTR, MSSQL, PostgreSQL"
                      customer="Richard Wolf GmbH, Knittlingen"
                      timespan="2010 bis 2016"
                      />
                     </div>
                </div>
              </div>
          </>
                     
        )
    }
}

export default ReferenceInfraStructure;


import { className } from "postcss-selector-parser";
import React from "react";
import { Component } from "react";

class TopicHeadline extends Component 
{ 
    render() 
    { 
        return (
            <>
                <div className={`spacing-headline  ${this.props.bgColor}`}></div>
                <h2 class={`${this.props.textColor}`}>{this.props.headline}</h2>
            </>
        )
    } 
} 

export default TopicHeadline;


import React, { Component } from "react";

class TextContainer extends Component
{
    render()
    {
        return (
            <>
                <div className={`text-container flex flex-col ${this.props.component}`}>
                    <hr className="hairline-card border-blue700 " />
                    <div className="card-title text-blue700">{this.props.title}</div>
                    <h4 className="text-blue700">{this.props.subtitle}</h4>
                    <br />
                    <p className="card-content text-blue700">{this.props.children}</p>
                </div>
            </>
        )           
    }
}

export default TextContainer;
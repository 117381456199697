import { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import "./Card.css";

import Button from './Button';


class Card extends Component
{
    render()
    {
        return (
            <>
    
    
                <Link to={this.props.link} className="card-flex-responsive spacing-content spacing-card ">
                    <img className={"card-img imgDarkBG " + (this.props.img ? 'block' : 'hidden')} src={this.props.img} />
                    <img className={"card-img imgBrightBG " + (this.props.imgFill ? 'onlyShowIfImgExists' : 'hidden')} src={this.props.imgFill} />
    
                    <div className="text-container card-text-container">
                        <hr className="hairline-card  border-white" />
                        <div className="card-title text-white">{this.props.title}</div>
                        <p className="card-content text-white">{this.props.content}</p>
    
                        <Button title="Mehr Infos"></Button>
    
                    </div>
                </Link>
            </>
        )
    
    }
}

export default Card;

import React, { useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import TextContainer from '../design-components/TextContainer.js';
import TextLineBreak from '../functional-components/TextLineBreak.js';

import { MenuItems } from '../navbar-with-dropdown/MenuItems';
import ImageMoCCA from '../../images/MoCCA.jpg';

export default function ReferenceMocca() {

  let referenceNumber = 1;
  referenceNumber--; //get Index for Array
  useEffect(() => {
    window.scrollTo(0, 0)
  },
    [])


  return (
    <>
      <section id='referenceMocca'>
        <div className="belowNavbar"></div>

        <h4 className="breadcrumb">
          <Link to='/#references' className="breadcrumb" >
            Referenzen
            <svg class="w-3 h-3 mx-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 5l7 7-7 7"></path>
            </svg>
          </Link>
          <span className="opacity-80">
            MoCCA
          </span>
        </h4>

        <div className="card-bg-responsive">
          <div className="spacing-content service-container">
            <div className="service-img-container">
              <img className="service-img" src={ImageMoCCA} alt="MoCCA Icon"></img>
            </div>
            <div className="service-text-container">
              <TextContainer
                title="MoCCA"
                content={
                  <TextLineBreak
                    text={MenuItems[referenceNumber].text} />
                }
              >
                <p>
                    Modular Car Computing Architecture ist ein C++ Framework, das in der Automobilindustrie zur Entwicklung von hoch skalierbaren Informations- 
                    und Entertainmentsystemen eingesetzt wird. Für die Erstellung der komponentenbasierten Systeme setzt MoCCA Konzepte der Model Driven Architecture (MDA) ein. 
                    Graphische Editoren ermöglichen die Modellierung von Schnittstellen, von zustands-abhängigem Code und der Interprozesskommunikation. 
                    Die Metadaten werden durch Codegeneratoren im Rahmen des automatisierten Build Prozesses in C++, Java, Docbook und SVG umgesetzt, 
                    um den Produktivcode und die Dokumentation aus den gleichen Quellen zu erzeugen.
                    Das Framework bietet verschiedene Arten von Regressionstests und unterstützt testgetriebene Entwicklung (TDD).
                </p>
                <p/>
                <hr/>
                <p>
                  <table>
                    <tr>
                      <th>Meine Aufgaben:</th>
                      <td>Konzeption und Durchführung der Schulungen und Workshops in Deutschland, USA, Kanada und Fernost. Unterstützung der Entwickler in den Projekten im Bereich der Architektur, Implementierung und Integration, sowie in der Erstellung von Testkonzepten. 
                          Rückführung der Erfahrungen aus den Projekten an das Framework Team, Mitarbeit an der Implementierung des Frameworks.
                      </td>
                    </tr>
                    <tr>
                      <th>Werkzeuge/Technologien:</th>
                      <td>MS Visual C++, Eclipse, Momentics, GCC/QCC, QNX, Perforce, MoCCA IDE, XML, UML, MDA, UnitTest, ComponentTest, System Test, TDD, DoxyGen, Docbook, XMLMind, MOST, MS Office, VMware</td>
                    </tr>
                    <tr>
                      <th>Kunde:</th>
                      <td>HARMAN/BECKER Automotive Systems GmbH, Karlsbad</td>
                    </tr>
                    <tr>
                      <th>Projektumfang</th>
                      <td>Das MoCCA Framework und die Projekte werden von einem interkulturellen Team von mehr als 100 Softwareentwicklern
                        und Architekten erstellt und kontinuierlich weiterentwickelt.</td>
                    </tr>
                    <tr>
                      <th>Zeitraum</th>
                      <td>2002 bis 2010</td>
                    </tr>
                  </table>
                </p>
              </TextContainer>
 	          </div>
          </div>
        </div>
      </section >
    </>
  );
}

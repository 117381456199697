import Icon1 from '../../images/icon-wheel.svg'
import Icon2 from '../../images/icon-shield.svg'
import Icon3 from '../../images/icon-prog.svg'
import Icon4 from '../../images/icon-method.svg'

import Image1 from '../../images/001-wheel-fill.svg'
import Image2 from '../../images/001-shield-fill.svg'
import Image3 from '../../images/001-prog-fill.svg'
import Image4 from '../../images/001-method-fill.svg'

export const MenuItems = [
  {
    title: 'Automotive SPICE',
    path: '/service1',
    cName: 'dropdown-link',
    icon: Icon1,
    image: Image1,
    text: 'ASPICE ist der weltweit führende Standard zur Beurteilung der Entwicklung von <i>softwaregesteuerten</i> Systemen im Automobil.\n\n',
  },
  {
    title: 'Funktionale Sicherheit ISO 26262',
    path: '/service2',
    cName: 'dropdown-link',
    icon: Icon2,
    image: Image2,
    text: 'Funktionale Sicherheit befasst sich mit Gefährdungen für Leib und Leben, die von elektrischen und/oder elektronischen Systeme in Straßenfahrzeugen ausgehen. \n\n'
    + 'ISO 26262 ist somit die Anpassung der IEC 61508 an die spezifischen Anforderungen an elektrische und/oder elektronische (E/E) Systeme in Straßenfahrzeugen',
  },
  {
    title: 'Programmier-Schulungen',
    path: '/service3',
    cName: 'dropdown-link',
    icon: Icon3,
    image: Image3,
    text: 'Programmier-Schulungen Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est.\n \n Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet',
  },
  {
    title: 'Methodische Schulungen und Software Engineering',
    path: '/service4',
    cName: 'dropdown-link',
    icon: Icon4,
    image: Image4,
    text: 'Die meisten Software Projekte scheitern nicht aufgrund der Programmierung selbst. Schlechte Infrastruktur, falsche Methodik oder unklare Anforderungen sind die häufigsten Problemursachen. \n\nAus mehr als 30 Jahren Praxiserfahrung biete ich Schulungen udn Workshops an, die sich mit dem Umfeld der Software Entwicklung befassen. \n\nThemen sind beispielsweise \n- Testgetriebene Softwareentwickung (TDD), \n- Modellgetriebene Architektur (MDA), \n- Umgang mit Versionskontrollsystemen aber auch \n- Critical Chain Project Management (CCPM)',
    },
  ];

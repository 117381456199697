import Card from './design-components/Card';
import TopicHeadline from './design-components/TopicHeadline'

export default function References() {
    return (
        <>
            <TopicHeadline headline="Referenzen" bgColor="" textColor="text-darkblue1" />
            <div className="card-bg-responsive bg-white">
                <Card
                    title="MoCCA"
                    link="/referenceMocca"
                    content="
                        Modular Car Computing Architecture ist ein C++ Framework, das in der 
                        Automobilindustrie zur Entwicklung von hoch skalierbaren Entertainment- 
                        und Informationssystemen eingesetzt wird.
                        "
                />
                <Card
                    title="PiezoLith3000Plus"
                    link="/referencePiezolith"
                    content="
                        Der PiezoLith 3000 ist ein medizinisches Therapiesystem zur 
                        extra-korporalen Stoßwellen Lithotripsie (ESWL). Er erlaubt die 
                        Zertrümmerung von z. B. Nieren-, Blasen- u. Gallensteinen ohne 
                        operativen Eingriff in den Körper. 
                    " 
                    unused="
                    Der Piezolith 3000 Plus ist ein System zur Behandlung von Nierensteinen mittels extrakorporaler Stoßwellenlithotripsie. Das Linux-basierte zentrale Steuersystem kommuniziert über verschiedene Schnittstellen mit den einzelnen Steuergeräten und den graphischen Bedieneinheiten.
                    In diesem Projekt war ich zunächst als Fullstack Entwickler tätig, übernahm aber danach auch die Aufgabe des Softwarearchitekten und des Anforderungsmanagers. Im Rahmen des Projekt führte ich beim Kunden testgetriebene und modellgetriebe Architektur mit Hilfe der Code-Generierungsfähigkieten von Qt ein.
                    Technologie: C++, Qt, SCXML, CORBA (TAO), Eclipse, Linux, Doors, Polarion, Enterprise Architect, SVN, Jira
                    Zeitraum: 2010 - 2016 " 
                />
                <Card
                    title="Hochintegrierte Entwicklungs-Infrastruktur"
                    link="/referenceInfraStructure"
                    content="
                        In diesem PRojekt wurde ein hochintegrierte Infrastuktue für die Entwicklung und die Zulassung von medizinischen  Geräten aufgebaut
                    "
                />

            </div>
        </>
    );

}